// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/inter/inter-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/assets/fonts/inter/inter-bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  background: #ede7d4;
  background-size: 800px 400px;
}

#mount {
  height: 100%;
}

/* Fonts */

@font-face {
  font-family: 'Inter';
  font-weight: normal;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA,UAAU;;AAEV;EACE,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,4CAAuD;AACzD;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;EAClB,4CAAoD;AACtD","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Inter', sans-serif;\n}\n\nhtml,\nbody {\n  height: 100%;\n  overflow-x: hidden;\n}\n\nbody {\n  background: #ede7d4;\n  background-size: 800px 400px;\n}\n\n#mount {\n  height: 100%;\n}\n\n/* Fonts */\n\n@font-face {\n  font-family: 'Inter';\n  font-weight: normal;\n  font-style: normal;\n  src: url('../src/assets/fonts/inter/inter-regular.ttf');\n}\n\n@font-face {\n  font-family: 'Inter';\n  font-weight: bold;\n  font-style: normal;\n  src: url('../src/assets/fonts/inter/inter-bold.ttf');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
